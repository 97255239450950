<template>
  <v-card>
    <v-card-text class="py-3">
      <v-row>
        <v-col cols="12" class="d-flex justify-space-between">
          <h1 class="page-title">USERS</h1>
          <v-btn text icon small color="gray" @click="closeDialog()">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="IS_SYS_ADMIN" class="d-flex justify-end">
        <v-col cols="12">
          <v-btn
            color="primary"
            @click="toggleDialog('viewCreateEdit')"
          >
            <v-icon>mdi-plus</v-icon>Add
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            v-model="filters.department_id"
            :items="DEPARTMENTS"
            label="Department"
            dense
            outlined
            hide-details
            auto-select-first
            clearable
            @input="loadData()"
          ></v-autocomplete>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="2">
          <v-text-field
            v-model="filters.search"
            placeholder="Search Name"
            append-icon="mdi-magnify"
            dense
            hide-details
            @keydown.enter="loadData()"
            @click:append="loadData()"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="dataTable.headers"
            :items="dataTable.items"
            :server-items-length="dataTable.total"
            :footer-props="{
              itemsPerPageOptions: [10, 20, 50, 100],
            }"
            :options.sync="dataTable.options"
            disable-sort
            @update:page="loadData()"
            @update:items-per-page="loadData()"
          >
            <template v-slot:[`item.locked`]="{ item }">
              <span class="badge bg-primary text-white" v-if="item.locked">Yes</span>
              <span class="badge bg-danger text-white" v-else>No</span>
            </template>
            <template v-slot:[`item.active`]="{ item }">
              <span class="badge bg-primary text-white" v-if="item.locked">Yes</span>
              <span class="badge bg-danger text-white" v-else>No</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn text icon color="orange">
                <v-icon class="btn-action" small @click="viewdialogs(item)">mdi-eye</v-icon>
              </v-btn>
              <v-btn text icon color="blue">
                <v-icon class="btn-action" small @click="viewdialogs(item)">mdi-pencil</v-icon>
              </v-btn>
              <v-btn text icon color="red">
                <v-icon class="btn-action" small @click="viewdialogs(item)">mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-dialog v-model="dialogs.viewCreateEdit" max-width="960" persistent>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
              <span class="text-h5">{{ formAction + ' User' }}</span>
              <v-row class="m-0">
                <v-col cols="pull-right-10 p-2">
                  <v-btn
                    text
                    icon
                    small
                    color="gray"
                    class="float-right"
                    @click="toggleDialog('viewCreateEdit')"
                  >
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="form.name"
                      :rules="error.name"
                      label="Name"
                      hide-details="auto"
                      dense
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="form.email"
                      :rules="error.email"
                      type="email"
                      label="Email"
                      hide-details="auto"
                      dense
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="form.username"
                      :rules="error.username"
                      label="Username"
                      hide-details="auto"
                      dense
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="form.mobile_no"
                      type="text"
                      label="Mobile No."
                      max="11"
                      dense
                      outlined
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      v-model="form.department_id"
                      :items="dropdowns.departments"
                      :rules="error.department_id"
                      label="Department"
                      hide-details="auto"
                      dense
                      outlined
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="form.job_title"
                      :rules="error.job_title"
                      label="Job Title"
                      hide-details="auto"
                      dense
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="3">
                    <v-switch
                      v-model="form.active"
                      label="active"
                      color="primary"
                      input-value="1"
                      hide-details
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <v-switch
                      v-model="form.locked"
                      label="locked"
                      color="primary"
                      input-value="1"
                      hide-details
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <v-switch
                      v-model="form.manager"
                      label="manager"
                      color="primary"
                      input-value="1"
                      hide-details
                    ></v-switch>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      type="number"
                      hide-spin-buttons
                      outlined
                      dense
                      label="token expiration time"
                      v-model="form.token_expiration_time"
                      @keyup="convertTohrs()"
                    >
                      <template #prepend-inner>
                        <span class="pt-1">min : </span>
                      </template>
                      <template #append>
                        <span class="pt-1">hrs: {{ convertTohrs() }}</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" lg="6" md="6">
                    <v-card>
                      <v-card-title class="pa-0 pl-2 border-bottom">
                          <span>Systems</span>
                      </v-card-title>
                      <v-card-text style="max-height: 250px; overflow: auto;">
                        <v-container fluid no-gutters>
                          <v-row no-gutters>
                            <v-col class="pa-1" cols="6" v-for="(system,i) in USER_SYSTEMS" :key="i">
                                <v-card class="mx-auto" @click="addSystem(system)">
                                  <v-overlay v-if="form.user_system.includes(system.id)" absolute>
                                    <v-icon color="green" transition="scale-transition">
                                    mdi-check-circle
                                  </v-icon>
                                  </v-overlay>
                                    <v-img
                                      contain
                                      class="img-thumbnail position-relative"
                                      :src="loadImage(system.image_path)">
                                   </v-img>
                                </v-card>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="text-center">
              <v-row class="ma-1">
                <v-col cols="12">
                  <v-btn
                    class="float-center"
                    color="primary"
                    @click="processUser(method,form.id)"
                  >
                    <span>{{ submitAction }}</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';

export default {
  data: () => ({
    loaded: false,
    valid: false,
    action: 'Create',
    filters: {
      department_id: null,
      search: null,
    },
    form: {
      name: null,
      password: null,
      password_confirmation: null,
      email: null,
      username: null,
      mobile_no: null,
      department_id: null,
      active: 1,
      locked: 0,
      job_title: null,
      manager: 0,
      token_expiration_time: 600,
      user_system:[],
    },
    error: {
      name: [(v) => !!v || 'Name is required'],
      email: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      username: [(v) => !!v || 'Username is required'],
      mobile_no: [(v) => !!v || 'Mobile is required'],
      password: [
        (v) => !!v || 'Password is required',
        // (v) => v.length >= 8 || 'Password must be greater than 8',
      ],
      password_confirmation: [
        (v) => !!v || 'Password is required',
        // (v) => v.length >= 8 || 'Password must be greater than 8',
        (v) => v === this.form.password || 'The password does not match.',
      ],
      department_id: [(v) => !!v || 'Department is required'],
      job_title: [(v) => !!v || 'Job Title is required'],
    },
    dataTable: {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Department', align: 'center', value: 'department_name' },
        { text: 'Locked', align: 'center', value: 'locked' },
        { text: 'Active', align: 'center', value: 'active' },
        { text: 'Actions', align: 'center', value: 'action' },
      ],
      items: [],
      options: {},
      total: 0,
      loading: false,
    },
    dropdowns: {
      departments: [],
    },
    dialogs: {
      viewCreateEdit: false,
    },
    method:''
  }),
  props: {
    toggled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'USERS',
      'DEPARTMENTS',
      'IS_SYS_ADMIN',
      'USER_SYSTEMS',
      'USERS_UPDATE'
    ]),
    formAction() {
      return this.action.charAt(0).toUpperCase() + this.action.substring(1).toLowerCase()
    },
    submitAction() {
      return this.action.toLowerCase() == 'create' ? 'Submit' : 'Update'
    },
  },
  methods: {
    async loadData() {
      await this.$store.dispatch('processUser', {
        method: 'get',
        params: {
          page: this.dataTable.options.page,
          itemsPerPage: this.dataTable.options.itemsPerPage,
          department_id: this.filters.department_id,
          search: this.filters.search,
        },
      });
    },
    async loadDropdowns() {
      await this.$store.dispatch('getDepartments', {
        url: 's/dropdown',
      });
    },
    async processUser(method = 'post', id = '') {
      let url = ''

      switch (method) {
        case 'get':
        case 'put':
        case 'delete':
          url = `/${id}`
          break
      }

      await this.$store.dispatch('processUser', {
        method: method,
        url: url,
        id: id,
        ...this.form,
      }).then(response => {
        swal.fire({
          title: response.title,
          text: response.text,
          icon: response.icon,
          timer: 3000,
        })
        if(method == 'post' || method == 'put'){
          this.dialogs.viewCreateEdit = false
          Object.assign(this.$data,this.$options.data.call(this))
        }
        this.loadData()
      })
    },
    convertTohrs(){
      let num = this.form.token_expiration_time?parseInt(this.form.token_expiration_time):0;
      let hr = num != NaN ?num / 60:0;
      return parseInt(hr);
    },
    toggleDialog(name) {
      this.dialogs[name] = !this.dialogs[name]
      if(!this.dialogs[name]){
        Object.assign(this.$data,this.$options.data.call(this))
         this.loadData();
        this.loadDropdowns();
      }
      this.method = 'post';
    },
    closeDialog() {
      this.$emit('closeDialog', false);
    },
    loadImage(image_path) {
        return `${process.env.VUE_APP_API_HOST}/loadImage?imagePath=${image_path}`
    },
    addSystem(system){
      if(this.form.user_system.includes(system.id)){
        this.form.user_system.splice(this.form.user_system.indexOf(system.id),1)
      }else{
        this.form.user_system.push(system.id)
      }
    },
    viewdialogs(item){
      this.form = item
      this.form.user_system = item.user_system.map(e=>e.system_id)
      this.dialogs.viewCreateEdit = true
      this.method = 'put'
    }
  },
  async mounted() {
    await this.loadData();
    await this.loadDropdowns();
  },
  watch: {
    'USERS': {
      handler(val) {
        this.dataTable.items = val.data;
        this.dataTable.total = val.total;
      }
    },
    'DEPARTMENTS': {
      handler(val) {
        this.dropdowns.departments = val;
      },
    },
  },
};
</script>
